import axios from 'axios'

/** Axios client instance. */
const client = axios.create({
    baseURL: '/api/',
    withCredentials: true, // enable cookies
})

/**
 * Make an API call and return a promise.
 *
 * @param {String} method GET or POST
 * @param {String} url The API path (not including /api/)
 * @param {Object} data The data to pass (POST only)
 * @param {Number} timeout The timeout for the request, in milliseconds.
 * @return {Promise} A promise which resolves to the response data, or rejects
 *      with an error message.
 */
export default function api(method, url, data, timeout = 30000) {
    return new Promise((resolve, reject) => {
        client.request({
            method, url, data, timeout
        }).then(response => {
            resolve((response.status === 204) ? null : response.data)
        }).catch(error => {
            let code = 600
            let message = 'The GTT Service Activation server could not be '
                + 'reached. Please check that your device is connected to '
                + 'the Internet, or try again later.'

            if (error.response) {
                code = error.response.status

                if ((code <= 500) && error.response.data.error) {
                    message = error.response.data.error
                }
            }

            reject({ code, message })
        })
    })
}
