import { useState } from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'

/**
 * Renders a card with the CPE details for the order, including a drop-down
 * to select which CPE to view if there's more than one.
 *
 * @param {Object} props The component properties.
 * @param {Object} props.cpe The currently selected CPE, if any.
 * @param {Array<Object>} props.allCpe An array of all CPE on the order.
 * @param {Function} props.setCpe A callback to change the selected CPE.
 */
export default function CpeCard({ cpe, allCpe, setCpe }) {
    const [ isOpen, setOpen ] = useState(false)

    return (<>
        <Card className="CpeCard">
            <Card.Header className="pt-3">
                {(allCpe.length > 1) &&
                    <Dropdown show={isOpen} onToggle={() => setOpen(o => !o)}
                            className="float-end">
                        <Dropdown.Toggle variant="primary">
                            Select CPE
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            {allCpe.map(thisCpe =>
                                <CpeMenuItem key={thisCpe.id} cpe={thisCpe}
                                    selected={cpe} setCpe={setCpe}/>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <Card.Title>CPE Details</Card.Title>
            </Card.Header>
            <Card.Body>
                {cpe ? <>
                    <div><b>GTT CPE ID:</b> {cpe.id}</div>
                    {cpe.make && <div><b>Make:</b> {cpe.make}</div>}
                    {cpe.model && <div><b>Model:</b> {cpe.model}</div>}
                    {cpe.hostname && <div><b>Hostname:</b> {cpe.hostname}</div>}
                </> : (
                    <div>Please Select a CPE from the dropdown above.</div>
                )}
            </Card.Body>
        </Card>
    </>)
}

CpeCard.propTypes = {
    cpe: PropTypes.object,
    setCpe: PropTypes.func.isRequired,
    allCpe: PropTypes.arrayOf(PropTypes.object).isRequired,
}

/**
 * Renders an item in the CPE-selection drop-down menu.
 *
 * @param {Object} props The component properties.
 * @param {Object} props.cpe The CPE.
 * @param {Object} props.selected The currently selected CPE, if any.
 * @param {Function} props.setCpe A callback to change the selected CPE.
 */
function CpeMenuItem({ cpe, selected, setCpe }) {
    const isSelected = selected && (cpe.id === selected.id)

    return (
        <Dropdown.Item onClick={() => setCpe(cpe)} active={isSelected}>
            {cpe.make} / {cpe.model}<br/>{cpe.hostname}
        </Dropdown.Item>
    )
}

CpeMenuItem.propTypes = {
    cpe: PropTypes.object.isRequired,
    selected: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
}
