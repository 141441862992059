import { useState } from 'react'
import {
    Alert, Button, ButtonToolbar, Card, Col, Form, Row
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import api from './api'
import Icon from './Icon'

/**
 * Renders the login page.
 *
 * @param {Object} props The component properties.
 * @param {Function} props.setOrder A callback to change the selected order.
 */
export default function LoginPage({ setOrder }) {
    const [ isLoading, setLoading ] = useState(false)
    const [ error, setError ] = useState()
    const [ orderId, setOrderId ] = useState('')
    const [ vendorOrderId, setVendorOrderId ] = useState('')
    const orderIdValid = !!orderId.match(/^\s*\d+\s*$/)
    const orderIdInvalid = !orderIdValid && (orderId.trim().length > 0)
    const vendorOrderIdValid = vendorOrderId.trim().length > 0
    const noSubmit = isLoading || !orderIdValid || !vendorOrderIdValid

    const onSubmit = e => {
        e.preventDefault()
        setLoading(true)
        setError(null)

        api(
            'POST', 'login', {
                order_id: orderId.trim(),
                vendor_order_id: vendorOrderId.trim()
            }
        ).then(
            order => setOrder(order)
        ).catch(
            ({ message }) => {
                setLoading(false)
                setError(message)
            }
        )
    }

    return (
        <Card className="LoginCard">
            <Form onSubmit={onSubmit}>
                <Card.Header className="pt-3">
                    <Card.Title>Service Activation Login</Card.Title>
                </Card.Header>
                <Card.Body>
                    {error &&
                        <Alert variant="danger">
                            <Icon fa="triangle-exclamation"/>
                            {error}
                        </Alert>
                    }
                    <p>To log into Service Activation, please enter a GTT
                        Order Number and Vendor Order ID.</p>
                    <InputRow required autoFocus label="GTT Order No." id="orderId"
                        value={orderId} onChange={setOrderId}
                        isValid={orderIdValid} isInvalid={orderIdInvalid}
                        className="mb-3"/>
                    <InputRow required label="Vendor Order ID" id="vendorOrderId"
                        value={vendorOrderId} onChange={setVendorOrderId}
                        isValid={vendorOrderIdValid}/>
                </Card.Body>
                <Card.Footer className="clearfix">
                    <ButtonToolbar className="float-end">
                        <Button type="submit" variant="gtt" disabled={noSubmit}>
                            {isLoading
                                ? <Icon fa="spinner" pulse/>
                                : <Icon fa="arrow-right-to-bracket"/>
                            }Log In
                        </Button>
                    </ButtonToolbar>
                </Card.Footer>
            </Form>
        </Card>
    )
}

LoginPage.propTypes = {
    setOrder: PropTypes.func.isRequired,
}

/**
 * Renders a form row, including label and input.
 *
 * @param {Object} props The component properties.
 * @param {Boolean} props.required Whether this is a required value.
 * @param {Boolean} props.autoFocus Whether the input should auto-focus.
 * @param {String} props.label The label text to display.
 * @param {String} props.id An HTML ID for the input.
 * @param {String} props.value The current valule of the input.
 * @param {Function} props.onChange A callback to change the value.
 */
function InputRow({ label, onChange, className, ...props }) {
    return (
        <Form.Group as={Row} className={className}>
            <Form.Label column xl={2} lg={3} md={4} sm={5} htmlFor={props.id}>
                {label}
            </Form.Label>
            <Col xl={10} lg={9} md={8} sm={7}>
                <Form.Control onChange={e => onChange(e.target.value)} {...props}/>
            </Col>
        </Form.Group>
    )
}

InputRow.propTypes = {
    autoFocus: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
}
