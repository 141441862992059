import { useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import Icon from './Icon'

/**
 * Renders the app footer, including an install prompt where available.
 */
export default function Footer() {
    const [ installPrompt, setInstallPrompt ] = useState()
    const year = useMemo(() => new Date().getFullYear(), [])

    useEffect(() => {
        const callback = e => {
            e.preventDefault()
            setInstallPrompt(e)
        }

        window.addEventListener('beforeinstallprompt', callback)

        return () => {
            window.removeEventListener('beforeinstallprompt', callback)
        }
    }, [])

    const addToHomeScreen = () => {
        setInstallPrompt(null)
        installPrompt.prompt()
    }

    return (<>
        {installPrompt &&
            <div className="Footer">
                <Button size="sm" onClick={addToHomeScreen}>
                    <Icon fa="square-plus"/>
                    Install This App on Your Device
                </Button>
            </div>
        }
        <div className="Footer">&copy; GTT Communications, Inc. {year}</div>
    </>)
}
