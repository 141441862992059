import { useState } from 'react'
import { Nav, Navbar, Button, Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import api from './api'
import Icon from './Icon'
import { ReactComponent as GTTLogo } from './gtt.svg'
import { ReactComponent as Activations } from './activations.svg'

/**
 * Renders the header bar, with logout button as appropriate.
 *
 * @param {Object} props The component properties.
 * @param {Object} props.order The currently selected order, if any.
 * @param {Function} props.setOrder A callback to change the selected order.
 */
export default function Header({ order, setOrder }) {
    const [ isLoading, setLoading ] = useState(false)

    const logout = () => {
        setLoading(true)
        api(
            'POST', 'logout'
        ).then(
            () => setOrder(null)
        ).finally(
            () => setLoading(false)
        )
    }

    return (
        <Navbar variant="dark" expand="sm" className="Header rounded">
            <Container>
                <Navbar.Brand href="https://gtt.net/" target="_blank"
                        rel="noopener noreferrer">
                    <GTTLogo/><Activations/>
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse>
                    <Nav className="ms-auto">
                        {order &&
                            <Button variant="secondary" onClick={logout}>
                                {isLoading
                                    ? <Icon fa="spinner" pulse/>
                                    : <Icon fa="arrow-right-from-bracket"/>
                                }Log Out
                            </Button>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

Header.propTypes = {
    order: PropTypes.object,
    setOrder: PropTypes.func.isRequired,
}
