import { StrictMode } from 'react'
import { render } from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './index.scss'

/* Render the application into the page. */
render(<StrictMode><App/></StrictMode>, document.getElementById('root'))

/* Register the service worker to allow app installs. */
serviceWorkerRegistration.register()

/* Log performance metrics in development. */
if (process.env.NODE_ENV === 'development') reportWebVitals(console.log)
