import { useState, useEffect } from 'react'
import { Button, Container } from 'react-bootstrap'
import api from './api'
import Footer from './Footer'
import Header from './Header'
import Icon from './Icon'
import Jumbotron from './Jumbotron'
import LoginPage from './LoginPage'
import OrderPage from './OrderPage'
import './App.scss'

/**
 * Renders the main application component.
 */
export default function App() {
    const [ order, setOrder ] = useState(null)
    const [ offline, setOffline ] = useState((navigator.onLine !== false) ? null
        : 'Your device appears to be offline. Please connect to the Internet'
            + ' and try again.')
    const [ isLoading, setLoading ] = useState(!offline)

    // After the first render, fire off an API call to check if we currently
    // have an order in the session.
    useEffect(() => {
        if (!offline) {
            api(
                'GET', 'get', null, 5000
            ).then(
                order => setOrder(order)
            ).catch(
                ({ code, message }) => setOffline((code > 500) ? message : null)
            ).finally(
                () => setLoading(false)
            )
        }
    }, [offline])

    const retry = () => {
        setLoading(true)
        setOffline(null)
    }

    return (
        <Container className="App">
            <Header order={order} setOrder={setOrder}/>
            { isLoading   ? <Jumbotron>
                                <h1><Icon fa="spinner" size="2x" pulse/></h1>
                            </Jumbotron>
                : offline ? <Jumbotron>
                                <p>{offline}</p>
                                <Button variant="gtt" onClick={retry}>
                                    <Icon fa="arrow-rotate-right"/>
                                    Retry Connection
                                </Button>
                            </Jumbotron>
                : order   ? <OrderPage order={order}/>
                          : <LoginPage setOrder={setOrder}/>
            }
            <Footer/>
        </Container>
    )
}
