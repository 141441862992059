import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'

/**
 * Renders a card displaying the order details.
 *
 * @param {Object} props The component properties.
 * @param {Number} props.id The order ID.
 * @param {String} props.vendor_id The vendor order ID.
 * @param {String} props.client The client name.
 * @param {String} props.site The site alias.
 * @param {String} props.address The site address.
 */
export default function OrderCard({ id, vendor_id, client, site, address }) {
    return (
        <Card className="OrderCard">
            <Card.Header className="pt-3">
                <Card.Title>Order Details</Card.Title>
            </Card.Header>
            <Card.Body>
                <div><b>GTT Service Order Number:</b> {id}</div>
                <div><b>Vendor Order ID:</b> {vendor_id}</div>
                <div><b>Client Name:</b> {client}</div>
                <div><b>Site/Store Name:</b> {site}</div>
                <div><b>Address:</b> {address}</div>
            </Card.Body>
        </Card>
    )
}

OrderCard.propTypes = {
    id: PropTypes.number.isRequired,
    vendor_id: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
}
