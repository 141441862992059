import { useState, useEffect } from 'react'
import {
    Alert, Button, ButtonToolbar, Col, Form, Modal, Row
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import api from './api'
import Icon from './Icon'

/**
 * Renders a modal dialog for posting results to an order.
 *
 * @param {Object} props The component properties.
 * @param {Boolean} props.isOpen Whether the dialog should be open.
 * @param {Number} props.cpeId The ID of the CPE.
 * @param {String} props.log The test results log.
 * @param {Function} props.onClose A callback to close the dialog.
 * @param {Function} props.onSuccess A callback after successfully posting the
 *      results to the order.
 */
export default function PostDialog({
    isOpen, allOk, cpeId, log, onClose, onSuccess
}) {
    const [ isLoading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ name, setName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ confirmed, setConfirmed ] = useState(false)
    const [ comments, setComments ] = useState('')
    const isInvalid = (name.trim().length === 0) || (email.trim().length === 0)

    const onHide = () => { if (!isLoading) onClose() }

    const onSubmit = e => {
        e.preventDefault()
        setLoading(true)
        setError(null)

        api(
            'POST', 'post', {
                cpe: cpeId, log, name, email, phone, comments,
                confirmed: confirmed ? 1 : 0
            }
        ).then(
            () => onSuccess()
        ).catch(
            ({ message }) => setError(message)
        ).finally(
            () => setLoading(false)
        )
    }

    useEffect(() => { setConfirmed(allOk) }, [ allOk ])

    return (
        <Modal size="lg" show={isOpen} onHide={onHide} className="PostDialog">
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Post Test Results</Modal.Title>
                </Modal.Header>
                {error &&
                    <Alert variant="danger">
                        <Icon fa="triangle-exclamation"/>
                        {error}
                    </Alert>
                }
                <Modal.Body>
                    <p>Please enter your details to post the test results back
                        to the GTT Service Order.</p>
                    <InputRow required autoFocus id="techName"
                            value={name} onChange={setName}>
                        Tech Name
                    </InputRow>
                    <InputRow required type="email" id="techEmail"
                            value={email} onChange={setEmail}>
                        Email
                    </InputRow>
                    <InputRow type="tel" id="techPhone"
                            value={phone} onChange={setPhone}>
                        Phone
                    </InputRow>
                    <InputRow as="textarea" id="techComments"
                            value={comments} onChange={setComments}>
                        Comments
                    </InputRow>
                    <InputRow type="checkbox" id="techConfirmed"
                            value={confirmed} onChange={setConfirmed}
                            disabled={!allOk}>
                        {allOk ? <span className={confirmed ? 'text-success'
                                : 'text-secondary'}>
                            Are you confirming that you have successful
                            connectivity between the CPE and GTT’s monitoring
                            system?
                        </span> : <span className="text-danger">
                            You cannot confirm connectivity to GTT's monitoring
                            system until all ping tests have successfully
                            completed.
                        </span>}
                    </InputRow>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button onClick={onHide} variant="secondary"
                                disabled={isLoading} className="me-2">
                            <Icon fa="xmark"/> Cancel
                        </Button>
                        <Button type="submit" variant="gtt"
                                disabled={isInvalid || isLoading}>
                            {isLoading
                                ? <Icon fa="spinner" pulse/>
                                : <Icon fa="paper-plane"/>
                            }Post to Order
                        </Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

PostDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    cpeId: PropTypes.number.isRequired,
    log: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

/**
 * Renders a form row, including label and input.
 *
 * @param {Object} props The component properties.
 * @param {Boolean} props.required Whether this is a required value.
 * @param {Boolean} props.autoFocus Whether the input should auto-focus.
 * @param {String} props.type The input type.
 * @param {String} props.id An HTML ID for the input.
 * @param {String} props.value The current value of the input.
 * @param {Function} props.onChange A callback to change the value.
 * @param {*} props.children The label to display.
 */
function InputRow({ onChange, children, value, ...props }) {
    if (props.type === 'checkbox') {
        return (
            <Form.Group as={Row} check>
                <Col lg={{ size: 10, offset: 2 }} sm={{ size: 9, offset: 3 }}>
                    <Form.Check onChange={e => onChange(e.target.checked)}
                        checked={value} label={children} {...props}/>
                </Col>
            </Form.Group>
        )
    }

    return (
        <Form.Group as={Row} className="mb-2">
            <Form.Label column lg={2} sm={3} htmlFor={props.id}>
                {props.required ? <b>{children}</b> : children}
            </Form.Label>
            <Col lg={10} sm={9}>
                <Form.Control onChange={e => onChange(e.target.value)} value={value}
                    {...props}/>
            </Col>
        </Form.Group>
    )
}

InputRow.propTypes = {
    autoFocus: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any.isRequired,
}
