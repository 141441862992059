import { useRef, useState } from 'react'
import {
    Alert, Button, ButtonToolbar, Card, Table
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import Icon from './Icon'
import InterfaceRow from './InterfaceRow'
import PostDialog from './PostDialog'

/**
 * Renders a card with the CPE interfaces, including buttons to run ping tests,
 * and a modal dialog to post the test results.
 *
 * @param {Object} props The component properties.
 * @param {Number} props.cpeId The selected CPE id.
 * @param {Array<Object>} props.interfaces An array of interfaces on the CPE.
 */
export default function InterfacesCard({ cpeId, interfaces }) {
    const [ log, setLog ] = useState('')
    const [ isPostOpen, setPostOpen ] = useState(false)
    const [ isPosted, setPosted ] = useState(false)
    const [ successes, setSuccesses ] = useState([])
    const pingers = useRef([])
    const allOk = successes.length === interfaces.length

    const onResult = (index, status, message) => {
        if (message) setLog(oldLog => {
            let newLog = message.trim()

            if (oldLog.length > 0) newLog = `${oldLog}\n${newLog}`

            return newLog
        })

        if (!status) {
            setSuccesses(is => is.filter(i => i !== index))
        } else if (!successes.find(i => i === index)) {
            setSuccesses(is => [ ...is, index])
        }
    }

    const runAllPingTests = () => { pingers.current.forEach(ping => ping()) }
    const onPostClose = () => { setPostOpen(false) }
    const onPostSuccess = () => {
        setPosted(true)
        setPostOpen(false)
    }

    if (interfaces.length === 0) return (
        <Alert variant="warning">
            <Icon fa="triangle-exclamation"/>
            No monitored interfaces were found on the selected CPE.
        </Alert>
    )

    return (<>
        <Card className="InterfacesCard">
            <Card.Header className="pt-3">
                {(interfaces.length > 1) &&
                    <Button className="PingAll float-end" variant="primary"
                            onClick={runAllPingTests}>
                        <Icon fa="tower-cell"/>
                        Run All
                        <span className="d-none d-sm-inline"> Tests</span>
                    </Button>
                }
                <Card.Title>Monitoring Test</Card.Title>
            </Card.Header>
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>IP Address</th>
                        <th className="d-none d-md-table-cell">VRF</th>
                        <th className="d-none d-sm-table-cell">
                            <span className="d-none d-lg-inline">Monitoring </span>
                            Type
                        </th>
                        <th className="d-none d-sm-table-cell">Class</th>
                        <th className="text-center">
                            Ping
                            <span className="d-none d-lg-inline"> Status</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {interfaces.map((iface, index) =>
                        <InterfaceRow key={iface.id} {...iface} index={index}
                            onResult={onResult} pingers={pingers}/>
                    )}
                </tbody>
            </Table>
            {log && <>
                <Card.Body className="PingResults">
                    <pre>{log}</pre>
                </Card.Body>
                <Card.Footer className="clearfix">
                    <ButtonToolbar className="float-end">
                        {isPosted ? (
                            <Button disabled variant="success">
                                <Icon fa="check"/>
                                Results Have Been Posted
                            </Button>
                        ) : (
                            <Button variant="gtt" onClick={() => setPostOpen(true)}>
                                <Icon fa="paper-plane"/>
                                Post Test Results to the GTT Order
                            </Button>
                        )}
                    </ButtonToolbar>
                </Card.Footer>
            </>}
        </Card>
        <PostDialog isOpen={isPostOpen} allOk={allOk} cpeId={cpeId} log={log}
            onClose={onPostClose} onSuccess={onPostSuccess}/>
    </>)
}

InterfacesCard.propTypes = {
    cpeId: PropTypes.number.isRequired,
    interfaces: PropTypes.arrayOf(PropTypes.object).isRequired,
}
