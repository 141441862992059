import { useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import CpeCard from './CpeCard'
import Icon from './Icon'
import InterfacesCard from './InterfacesCard'
import OrderCard from './OrderCard'

/**
 * Renders the order page.
 *
 * @param {Object} props The component properties.
 * @param {Object} props.order The currently selected order.
 */
export default function OrderPage({ order }) {
    const allCpe = order.cpe
    const [cpe, setCpe ] = useState((allCpe.length === 1) ? allCpe[0] : null)

    if (allCpe.length === 0) return (<>
        <OrderCard {...order}/>
        <Alert variant="waring">
            <Icon fa="triangle-exclamation"/>
            No CPE with monitored interfaces were found on this order.
        </Alert>
    </>)

    return (<>
        <Row>
            <Col md={6}>
                <OrderCard {...order}/>
            </Col>
            <Col md={6}>
                <CpeCard cpe={cpe} setCpe={setCpe} allCpe={allCpe}/>
            </Col>
        </Row>
        {cpe &&
            <InterfacesCard key={cpe.id} cpeId={cpe.id}
                interfaces={cpe.interfaces}/>
        }
    </>)
}

OrderPage.propTypes = {
    order: PropTypes.object.isRequired,
}
