import { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'
import api from './api'
import Icon from './Icon'

/**
 * Renders a row of the interfaces table.
 *
 * @param {Object} props The component properties.
 * @param {Number} props.id The interface ID.
 * @param {String} props.class The interface class.
 * @param {String} props.ip The IP address.
 * @param {String} props.vrf The VRF the interface is in, if any.
 * @param {String} props.profile The monitoring profile.
 * @param {Number} props.index The index of this interface.
 * @param {Function} props.appendLog A callback to append text to the log.
 * @param {React.MutableRefObject<Array<Function>>} props.pingers A reference
 *      to an array of ping callbacks by interface index.
 */
export default function InterfaceRow({
    id, class: clazz, ip, vrf, profile, index, onResult, pingers
}) {
    const [ buttonColor, setButtonColor ] = useState('primary')
    const [ buttonIcon, setButtonIcon ] = useState('wifi')
    const [ buttonText, setButtonText ] = useState('Run')
    const [ isLoading, setLoading ] = useState(false)

    const ping = () => {
        if (isLoading) return // shortcut to avoid duplicate pings

        setLoading(true)
        setButtonColor('secondary')
        onResult(index, false)

        api(
            'POST', 'ping', { id }
        ).then(
            ({
                // code,    // state code (0 - 3)
                ip,         // IPv4 address, e.g. "10.140.53.89"
                message,    // message to add to the test log
                result,     // boolean value indicating reachability of the IP
                state,      // state text (UP/WARNING/CRITICAL/UNKNOWN)
            }) => {
                onResult(index, result, `${ip}: ${message}`)
                setButtonText(state)

                if (result) {
                    setButtonColor('success')
                    setButtonIcon('check')
                } else {
                    setButtonColor('danger')
                    setButtonIcon('xmark')
                }
            }
        ).catch(
            ({ message }) => {
                onResult(index, false,
                    `${ip}: Failed to perform ping check (${message})`)
                setButtonText('Failed')
                setButtonColor('warning')
            }
        ).finally(
            () => setLoading(false)
        )
    }

    pingers.current[index] = ping

    return (
        <tr className="InterfaceRow">
            <td>
                <span>{ip}</span>
                <small className="d-block d-sm-none text-secondary">
                    Type: {profile} &nbsp; Class: {clazz}
                </small>
            </td>
            <td className="d-none d-md-table-cell">{vrf}</td>
            <td className="d-none d-sm-table-cell">{profile}</td>
            <td className="d-none d-sm-table-cell">{clazz}</td>
            <td className="PingCell">
                <Button variant={buttonColor} onClick={ping} disabled={isLoading}>
                    {isLoading
                        ? <Spinner size="sm" animation="grow" variant="dark"/>
                        : <><Icon fa={buttonIcon}/>{buttonText}</>
                    }
                </Button>
            </td>
        </tr>
    )
}

InterfaceRow.propTypes = {
    id: PropTypes.number.isRequired,
    class: PropTypes.string.isRequired,
    ip: PropTypes.string.isRequired,
    vrf: PropTypes.string,
    profile: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onResult: PropTypes.func.isRequired,
    pingers: PropTypes.shape({
        current: PropTypes.arrayOf(PropTypes.func)
    }).isRequired
}
