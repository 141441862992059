import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faPaperPlane,
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faArrowRotateRight,
    faSpinner,
    faSquarePlus,
    faTowerCell,
    faTriangleExclamation,
    faWifi,
    faXmark,
} from '@fortawesome/pro-light-svg-icons'

/* Cache the FontAwesome icons we'll be using. */
library.add(
    faCheck,
    faPaperPlane,
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faArrowRotateRight,
    faSpinner,
    faSquarePlus,
    faTowerCell,
    faTriangleExclamation,
    faWifi,
    faXmark,
)

export default function Icon({ fa, ...props }) {
    return <FontAwesomeIcon icon={[ 'fal', fa ]} {...props}/>
}
